import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import ValuesLinks from "../components/ValuesLinks";
import ScrollAnimation from "../components/ScrollAnimation";
import { Helmet } from "react-helmet";

const title = "Adorare";

const AdorarePage: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    headlines {
                        adorare
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="image-bordered">
                                <StaticImage
                                    src="../images/adorare.jpg"
                                    alt={title}
                                />
                            </div>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3 className="headline">{data.site.siteMetadata.headlines.adorare}</h3>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <blockquote className="blockquote">
                                    <p>&quot;Vă îndemn dar, fraţilor, pentru îndurarea lui Dumnezeu, să aduceţi trupurile voastre ca o jertfă vie, sfântă, plăcută lui Dumnezeu; aceasta va fi din partea voastră o slujbă duhovnicească.&quot;</p>
                                    <footer>Romani 12:1</footer>
                                </blockquote>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Adorarea este răspunsul inimii la tot ce este și face Dumnezeu. Închinarea înseamnă să-L adori pe Dumnezeu pentru ce este El, să recunoști cât de mare este Dumnezeu și cât de mic ești tu.</p>
                                <p>Am fost creați să ne închinam Lui și să ne bucurăm de El pentru totdeauna!</p>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ValuesLinks active={1} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default AdorarePage;
